import React, { useContext } from "react"

import { useAnonymousToken } from "../hooks"
import { JungState } from "../../plugins/gatsby-plugin-jung-wrapper/JungContext"

import "./InitialiseAudio.scss"

const InitialiseAudio = ({ text = "Enable audio" }) => {
    let jungContext = useContext(JungState)
    let { loading, anonymousToken } = useAnonymousToken()

    return (
        <div className="initialiseAudio">
            {!loading && (
                <button className="button" onClick={() => jungContext.audioOn({ anonymousToken })}>
                    {text}
                </button>
            )}
        </div>
    )
}

export default InitialiseAudio
