import React from "react"

import "./VolumeControl.scss"

const VolumeControl = ({ volume, setVolume }) => {
    return (
        <div className="volumeControl">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 48">
                <path
                    className="speaker"
                    d="M30,0a2,2,0,0,0-1.41.59L15.17,14H4a4,4,0,0,0-4,4V30a4,4,0,0,0,4,4H15.17L28.59,47.41A2,2,0,0,0,30,48a2,2,0,0,0,2-2V2A2,2,0,0,0,30,0ZM28,22V41.17L17.41,30.59A2,2,0,0,0,16,30H4V18H16a2,2,0,0,0,1.41-.59L28,6.83Z"
                />
                <path
                    className="soundWave"
                    d="M49.12.88,46.27,3.73a26,26,0,0,1,0,40.54l2.85,2.85a30,30,0,0,0,0-46.24Z"
                />
                <path
                    className="soundWave"
                    d="M40.56,9.44,37.69,12.3a14,14,0,0,1,0,23.38l2.86,2.87a18,18,0,0,0,0-29.11Z"
                />
            </svg>
            <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={(evt) => setVolume(+evt.target.value)}
            />
        </div>
    )
}

export default VolumeControl
