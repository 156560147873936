import React, { useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { detect } from "detect-browser"

import "./UnsupportedBrowser.scss"

const UnsupportedBrowser = ({ location }) => {
    let [copied, setCopied] = useState(false)

    return (
        <div className="unsupportedBrowser">
            <div className="unsupportedBrowser--message">
                Sorry, but this browser does not support Wavepaths Journeys.
            </div>
            <div className="unsupportedBrowser--message">
                Please take the following link and open it in Safari, or use another device.
            </div>
            <CopyToClipboard text={location.href} onCopy={() => setCopied(true)}>
                <button className="button">{copied ? <>Copied</> : <>Copy link</>}</button>
            </CopyToClipboard>
        </div>
    )
}

export default UnsupportedBrowser
