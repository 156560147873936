import React from "react"
import TransitionLink, { useTriggerTransition } from "gatsby-plugin-transition-link"
import _ from "lodash"
import { useMixpanel } from "../../plugins/gatsby-plugin-mixpanel"

const FadeLink = (props) => {
    const mixpanel = useMixpanel()

    const handleOnClick = () => {
        if (props.onClick) {
            props.onClick()
        }
        mixpanel.track(`Clicked a link to ${_.trimEnd(props.to, "/")} from ${_.trimEnd(props.from, "/")}`)
    }
    return (
        <TransitionLink
            {...props}
            onClick={handleOnClick}
            exit={{ ...props.exit, length: 1 }}
            entry={{ ...props.entry, delay: 1 }}
            trigger={() => {
                // Workaround for gatsby-plugin-transition-link behaviour where it used location.pathname only
                // while checking if back button was pressed mid-transition. Query strings break it.
                // https://github.com/TylerBarnes/gatsby-plugin-transition-link/blob/143bb0074bd37570556b14168839a533db91aeb8/src/gatsby-browser.js
                let desiredPath = window.__tl_desiredPathname
                if (desiredPath && desiredPath.indexOf("?") > 0) {
                    window.__tl_desiredPathname = desiredPath.substring(0, desiredPath.indexOf("?"))
                }
            }}
        />
    )
}

export const useFadeTransition = (props) =>
    useTriggerTransition({
        ...props,
        exit: { ...props.exit, length: 1 },
        entry: { ...props.entry, length: 1 },
    })

export default FadeLink
