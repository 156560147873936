import React, { useContext, useState, useEffect, useCallback } from "react"
import classNames from "classnames"

import { JungContext, isVolumeAdjustable } from "../../plugins/gatsby-plugin-jung-wrapper/JungContext"
import * as freudApi from "../util/freudApiUtil"
import { useAnonymousToken } from "../hooks"

import VolumeControl from "./VolumeControl"

import "./AudioControl.scss"

const AudioControl = ({ autoplayStream, volumeOnly, isUpsideDown, isAnchorRight }) => {
    const { anonymousToken } = useAnonymousToken()
    const jungContext = useContext(JungContext)
    let [volume, setVolume] = useState(jungContext.masterVolume)
    let [_, setStateCnt] = useState(0)
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        let unSubAudio = jungContext.onAudioStateChange(() => {
            setVolume(jungContext.masterVolume)
            setStateCnt((c) => c + 1)
        })
        let unSubState = jungContext.onStateChange(() => setStateCnt((s) => s + 1))
        return () => {
            unSubAudio()
            unSubState()
        }
    }, [jungContext])

    useEffect(
        function loadStreams() {
            if (!anonymousToken || volumeOnly) return

            freudApi.listStreams(anonymousToken).then((streams) => {
                if (streams.length > 0) {
                    let preferredStreamId = getPreferredStreamId()
                    let streamCurrentlyPlaying =
                        jungContext.session && streams.find((s) => s.broadcastIdentifier === jungContext.session)
                    let streamToPlay =
                        streamCurrentlyPlaying ||
                        streams.find((s) => s.broadcastIdentifier === preferredStreamId) ||
                        streams[0]
                    jungContext.setSession(streamToPlay.broadcastIdentifier, {
                        anonymousToken,
                        isInfinite: true,
                    })
                    setPreferredStreamId(streamToPlay.broadcastIdentifier)
                    if (autoplayStream && jungContext.canAutoplayAudio()) {
                        jungContext.audioOn({ anonymousToken })
                    }
                }
            })
        },
        [jungContext, anonymousToken, volumeOnly, autoplayStream]
    )

    let onUpdateVolume = useCallback(
        (vol) => {
            jungContext.setMasterVolume(vol)
        },
        [volumeOnly, jungContext, anonymousToken]
    )

    return (
        <div
            className={classNames("audioControl", {
                isVolumeOnly: volumeOnly,
                isVolumeAdjustable: isVolumeAdjustable(),
                isPlaying: jungContext.isAudioOn(),
                isAnchorRight,
            })}
        >
            <button
                className={classNames("audioControlToggle", {
                    isVisible: !!visible,
                })}
                onClick={() => setVisible(visible ? false : true)}
            >
                <span className="sr-only">Toggle Audio Controls</span>
                <svg className="iconAudio" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 6">
                    <circle className="circle circleOne" cx="3" cy="3" r="3" />
                    <circle className="circle circleTwo" cx="14" cy="3" r="3" />
                    <circle className="circle circleThree" cx="25" cy="3" r="3" />
                    <circle className="circle circleFour" cx="36" cy="3" r="3" />
                </svg>
            </button>
            <div
                className={classNames("audioControlContent", {
                    isVisible: visible,
                    isUpsideDown: isUpsideDown,
                })}
            >
                <div className="audioControlButtons">
                    {!volumeOnly && jungContext.isAudioOff() && jungContext.session && (
                        <button
                            className="audioControlButton audioControlButton--play"
                            onClick={() => jungContext.audioOn({ anonymousToken })}
                        >
                            <span className="sr-only">Play</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
                                <path d="M4.8,8.7V3.3L8.4,6M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0Z" />
                            </svg>
                        </button>
                    )}
                    {!volumeOnly && jungContext.isAudioOn() && (
                        <button
                            className="audioControlButton audioControlButton--pause"
                            onClick={() => jungContext.audioOff()}
                        >
                            <span className="sr-only">Pause</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
                                <path d="M7.8,8.4H6.6V3.6H7.8M5.4,8.4H4.2V3.6H5.4M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0Z" />
                            </svg>
                        </button>
                    )}
                </div>
                <VolumeControl volume={volume} setVolume={onUpdateVolume} />
            </div>
        </div>
    )
}

function getPreferredStreamId() {
    return localStorage.getItem("preferredStreamId")
}
function setPreferredStreamId(stream) {
    localStorage.setItem("preferredStreamId", stream)
}

export default AudioControl
