import { useState, useEffect } from "react"
import { nanoid } from "nanoid"

type UseAnonymousTokenReturnType = {
    anonymousToken?: string
    loading: boolean
}

/**
 * Like useUserData(), but when there is no user signed in, provides an "anonymousToken" instead.
 * This token is unique and semi-stable for the browser (localStorage based)
 */
const useAnonymousToken = (): UseAnonymousTokenReturnType => {
    const [anonymousToken, setAnonymousToken] = useState<string | undefined>()

    useEffect(() => {
        setAnonymousToken(obtainAnonymousToken())
    }, [])

    return {
        anonymousToken,
        loading: !anonymousToken,
    }
}

function obtainAnonymousToken() {
    const existingToken = localStorage.getItem("anonymousToken")
    if (existingToken) {
        return existingToken
    } else {
        const newToken = nanoid()
        localStorage.setItem("anonymousToken", newToken)
        return newToken
    }
}

export default useAnonymousToken
